import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "identification": "SpoorSupplyChain",
  "supplychain-title": "Supply Chain Visibility",
  "supplychain-subtitle": "Increased transparency on Sea and Land",
  "supplychain-paragraph": "Track your cargo, special cargo and equipment with Spoor. Designed to be simple, fast and beautiful. Our customizable and intuitive web interface gives you and your customers real insights into your supply chain.",
  "supplychain-button-title": "I want to know more!",
  "supplychain-button-link": "/"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      